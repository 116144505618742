import React from 'react';
import { css } from '@emotion/react';
import { useRegistration } from '../services/typeformRegistration';
import { color } from '../components/defaults/styles/theme';
import { IconButtonBtn } from '../components/defaults/styles/elements';
import Link from '../components/link';
import Business from '../components/defaults/assets/svg/business.svg';
import Bulb from '../components/defaults/assets/svg/bulb.svg';
import Users from '../components/defaults/assets/svg/users.svg';
import Agenda from '../components/defaults/assets/svg/agenda.svg';
import Archive from '../components/defaults/assets/svg/archive.svg';
import Ticket from '../components/defaults/assets/svg/ticket.svg';
import Play from '../components/defaults/assets/svg/play.svg';
import Info from '../components/defaults/assets/svg/info.svg';
import Rearview from '../components/defaults/assets/svg/rearview.svg';
import Flags from '../data/raw/flags';

const RegisterButton = ({className, ...rest}) => {
  const {registerButton, toggle} = useRegistration()

  return (
    <IconButtonBtn
      className={className}
      {...rest}
      css={css`
        padding: 0.35rem 0.8rem;
        letter-spacing: 0.025em;
        font-weight: 600;
      `}
      onClick={toggle}
      name={registerButton.buttonText}
    />
  )
}

const PreheaderContent = () => {
  const {registerButton, toggle} = useRegistration()
  return (
    <>
      <p>
        <span
          css={css`
            font-weight: 600;
          `}
        >
          {Flags.features.live ? Flags.settings.live.title : "July 21st - 23rd, 2021:" }
        </span>{" "}
        {Flags.features.live ? "Join the STARTUP AUTOBAHN powered by Plug and Play EXPO 10 Finale" : "Rewatch the STARTUP AUTOBAHN powered by Plug and Play EXPO 10."}{" "}
        {Flags.features.live ? (
          <Link
            css={css`
              font-weight: 600;
            `}
            to={Flags.settings.live.destination}
          >
            Join Now
          </Link>
        ) : Flags.features.main_registration ? (
          <button css={css`
          border: none;
          background: none;
          font-weight: 600;
          cursor: pointer;
          padding: 0;
        `} onClick={toggle}>{registerButton.buttonText}</button>
        ) : Flags.features.rearview ? (
          <Link
            css={css`
              font-weight: 600;
            `}
            to={`/rearview`}
          >
            Rewatch
          </Link> 
        ) : null}
      </p>
    </>
  )
}

const navigation = {
  preheader: {
    info: {
      block: Flags.features.live ? "LIVE • EXPO 10" : "EXPO 10",
      content: <PreheaderContent />,
    },
    navigation: [
      {
        title: "startup-autobahn.com",
        url: "https://startup-autobahn.com"
      },
      {
        title: "Contact",
        url: "mailto:hannah@pnptc.com"
      }
    ]
  },
  header: {
    navigation: [
      // Explanation of the navigation element
      // ______________
      // There are two different variants of clickable elements that currently can be added.
      // A simple Link to a single location will be added like below:
      //
      // {
      //   title: "This is the displayed title of the element",
      //   url: "And this is the location that will be opened up upon click."
      // }
      //
      // The second variation is a dropdown menu. A dropdown can include unlimited sub-elements
      // including "title", "teaser", "url" and a third level of links that is basically a list
      // with various destinations.
      // A maxed out and fully configured section would look like this:
      // {
      //   title: "A Dropdown",
      //   items: [
      //     {
      //       title: "This is the First Element of the Dropdown",
      //       teaser: "This Element also includes a teaser sentence and is clickable itself because it features a url",
      //       url: "That is the destination",
      //       background: red;
      //     },
      //     {
      //       title: "This is the Second Element of the Dropdown",
      //       teaser: "This Element features not just a url but also a nested list of further locations",
      //       url: "That is the destination",
      //       items: [
      //         {
      //           title: "The sublist can only feature a title and the destination url",
      //           url: "https://bastiankroggel.com" 
      //         },
      //         {
      //           title: "The sublist can only feature a title and the destination url",
      //           url: "https://bastiankroggel.com" 
      //         },
      //         {
      //           title: "The sublist can only feature a title and the destination url",
      //           url: "https://bastiankroggel.com" 
      //         },
      //       ]
      //     },
      //   ]
      // }
      {
        title: "Startups",
        url: "/startups",
        icon: <Bulb />
      },
      {
        title: "Partners",
        url: "/partners",
        icon: <Business />,
      },
      {
        title: "Agenda",
        icon: <Agenda />,
        items: [
          {
            title: "Day 1",
            teaser: "Grand Opening with top-industry level voices and highlight some of the most innovative, game-changing technologies that have graduated from our program",
            url: "/agenda/day1",
            background: Flags.features.live && Flags.settings.live.date === "2021-07-21" ? color.plue100 : "none",
          },
          {
            title: "Day 2",
            teaser: "Join the pilot sessions and discuss with the startups, partners, keynote speakers, and panel experts the newest tech trends.",
            url: "/agenda/day2",
            background: Flags.features.live && Flags.settings.live.date === "2021-07-22" ? color.plue100 : "none",
            items: [
              {
                title: "Stream Sessions",
                url: "/agenda/day2/stream"
              },
              {
                title: "Breakout Sessions",
                url: "/agenda/day2/breakout"
              },
            ]
          },
          {
            title: "Day 3",
            teaser: "The big EXPO 10 finale with Markus Schäfer, Member of the Board of Management of Daimler AG and Mercedes-Benz AG and Olympic Champion Jan Frodeno.",
            url: "/agenda/day3",
            background: Flags.features.live && Flags.settings.live.date === "2021-07-23" ? color.plue100 : "none",
          },
        ]
      },
      {
        title: "People",
        icon: <Users />,
        items: [
          {
            title: "All people",
            url: "/people/all",
            teaser: "Find out more about who is attending EXPO 10"
          },
          {
            title: "Speaker",
            teaser: "The people on our EXPO 10 stage.",
            url: "/people/speakers"
          },
          {
            title: "Corporate Champions",
            url: "/people/champions",
            teaser: "Everything about our the STARTUP AUTOBAHN Champions of our partner corporations."
          },
          {
            title: "Startup Employees",
            teaser: "Check out the brilliant minds behind the STARTUP AUTOBAHN startups.",
            url: "/people/startupemployees"
          },
          {
            title: "EXPO Management",
            url: "/people/ops",
            teaser: "In case you were wondering who is behind the scenes at EXPO 10"
          },
        ]
      },
      {
        title: "Rearview",
        icon: <Rearview />,
        url: "/rearview"
      },
      {
        title: "About",
        icon: <Info />,
        iconStroke: true,
        items: [
          {
            title: "Press Release(s)",
            teaser: "Press Releases of Partners and Startups can be found right in their respective Profiles.",
            items: [
              {
                title: "Open Partner Profiles",
                url: "/partners"
              },
              {
                title: "Open Startup Profiles",
                url: "/startups"
              }
            ]

          },
          {
            title: "STARTUP AUTOBAHN website",
            url: "https://startup-autobahn.com",
            teaser: "If you would like to get to know more about STARTUP AUTOBAHN, its partners, startups and program feel free to head over to the official website."
          },
          {
            title: "Archive",
            url: "/archive",
            teaser: "We invite you to check out our past EXPOs and to go back in time and take a look at the first 5-years of STARTUP AUTOBAHN together with us."
          },
          {
            title: "Miscellaneous",
            teaser: "Just in case you are interested & for your information.",
            items: [
              {
                title: "Imprint",
                url: "/imprint"
              },
              {
               title: "Privacy Policy & Legal",
               url: "/privacy-policy"
              },
              {
               title: "Sitemap",
               url: "/sitemap-index.xml"
              }
            ]
          }
        ]
      }
    ],
    cta: [
      {
        content: <RegisterButton />,
        type: "raw",
        active: false,
      },
      {
        title: "Rearview",
        url: "/rearview",
        type: "link"
      },
      {
        title: "Live",
        url: Flags.settings.live.destination,
        type: "link",
        customColor: {
          light: color.plue_rec,
          dark: color.plue_rec
        },
        active: Flags.features.live ? true : false,
      },
      // {
      //   title: "bastiankroggel.com",
      //   url: "https://bastiankroggel.com",
      //   type: "link"
      // },
    ]
  },
  mobile: {
    intro: {
      content: Flags.features.live ? "<p>Come in, we’re live.</p><p>In order to join our livestream and sessions just click on the button below and you will be guided to the right destination." : Flags.features.rearview ? "<p>To rewatch our EXPO 10 click on the button below and select the sessions and streams you would like to relive.</p>" : "<p>To safe a spot and to don‘t miss out on any updates regarding EXPO 10 click on the button below to get one of the tickets.</p>",
      // button: {
      //   title: "Register Now",
      //   icon: <Bulb />,
      //   url: "https://bastiankroggel.com",
      // }
      buttons: [
        {
          title: "We are live. Join now!",
          url: Flags.settings.live.destination,
          icon: <Play />,
          customColor: {
            background: color.plue_rec,
          },
          active: Flags.features.live ? true : false, 
        },
        {
          raw:  
            <RegisterButton 
              icon={<Ticket />}
              css={css`
                width: 100%;
                display: inline-flex;
                align-items: center;
                text-align: left;
                svg {
                  fill: white;
                  stroke: none;
                }
                &:before {
                  background: ${color.plue_light};
                }
              `}
            />,
          active: false,
        },
        {
          title: "Check out the EXPO10 Rearview",
          url: "/rearview",
          icon: <Rearview />,
          active: Flags.features.rearview ? true : false, 
        }
      ]
    },
    navigation: [
      {
        title: "People",
        url: "/people/all",
        icon: <Users />,
      },
      {
        title: "Startups",
        url: "/startups",
        icon: <Bulb />,
      },
      {
        title: "Partners",
        icon: <Business />,
        url: "/partners"
      },
      {
        title: "Agenda",
        icon: <Agenda />,
        url: "/agenda/day3"
      },
    ]
  }
};

export default navigation;
