import React from "react"
import { css } from "@emotion/react"
import Link from '../../link';
import { color } from '../styles/theme';

const NavContent = ({ data }) => {
    return (
        <>
            <ul css={css`
                margin: 0;
                list-style: none;
                li {
                    margin: 0;
                    font-size: .75rem;
                    line-height: 1em;
                    opacity: .475;
                    display: block;
                    &:last-of-type {
                      padding-bottom: 1.5em;
                    }
                }
            `}>
                {data.map((item, index) => (
                    <li key={index}>
                        <Link to={item.url} css={css`
                            text-decoration: none;
                            color: ${color.main_dark};
                            padding-top: .85em;
                            padding-bottom: .85em;
                            display: block;
                `}>{item.name}</Link></li>
                ))}
            </ul>
        </>
    )
}

export default NavContent;