module.exports = {
  settings : {
    // ATTENTION
    // - make sure to also adapt the title of the overall routing page to the same as the one to be
    //   found under "url"
    // - adapt the route which can be found under "onCreatePage" in the gatsby-node.js file
    protectedArea : {
      url: "inside",
      title: "Members Area"
    },
    live : {
      //destination : "/live",
      destination: "/live",
      title : "July 23rd — Day 3",
      date: "2021-07-24"
    },
    dummySettings: {
      // companies listed under company will be excluded from being displayed on the website (peopleProfile)
      // and therefore can serve as dummy values on airtable that never show up (takes a string or an array)
      company: ["Custom Company", "DummyStartup"]
    }
  },
  features : {
    // if "anonymize"
    // - startup information will be replaced by dummy default content and hidden behind a blurred out layer
    // - startups are not active and cannot be clicked
    // if false
    // - startups are shown properly 
    // - the respective sub-pages cannot be accessed
    //startups: "anonymize",
    startups: true,
    // if people === true || people === "profile_only"
    // - who profile button on contact element
    // - show champion in corporate profile
    // - show startup employees on startup profile
    // - show contact in agenda elements
    // if people === true
    // - show executives in corporate profile
    // - show corporate attendees
    people: true,
    // if rearview:
    // - show REAЯVIEW element on startup profile
    // - show REAЯVIEW element on partner profile
    // - show REAЯVIEW element on people profile
    // - show REAЯVIEW element on event session sites
    rearview: true,
    // if allAccess:
    // - show button on navigation bar
    allAccess: true,
    // if agenda:
    // - show agenda items in peoples profile
    // - shows "speaker" and "host" badge on
    // - show "origins from" element on rearview
    agenda: true,
    // if testimonial:
    // - testimonial video will be visible on partner profiles
    testimonial: true,
    // if testimonial_profile & testimonial
    // - people information will be displayed right below video and information & video will pop up on peoples profile
    testimonial_profile: false,
    // if registration (not main registration):
    // - registration buttons will be visible on respective elements
    //   (see for handler: services/registration.js)
    registration: false,
    // - button on hero animation 
    // - 
    main_registration: false,
    // if live:
    // - Live button in desktop nav will be activated
    // - Live button in mobile drawer will be activated
    // - live indicator on mobile nav will be activated
    // - pre header info element will start blinking
    // - Live button will appear on hero animation
    // - Live button on agenda will be activated
    // - buttons on event page will change color and join button under "MORE"
    //   will be activated
    // - bottom info box right above footer will be switched out according to setup in general_footer
    live: false,
    // if true:
    // -show livestream indicator in hero section
    // livestream: false,
    // if true:
    // - show casestudy on startup (incl. download)
    // - show casestudy link on agenda
    // - show casestudy link on partner profile
    casestudy: true,
    // if pressrelease live.
    // - show pressrelease on partner profiles
    // - show pressrelease on startup profiles
    pressrelease: true,
  }, 
}