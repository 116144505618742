import React, { useState, useEffect } from 'react';
// import RegisterNow from './registernow';
import FooterBox from './template_footer_element';
import Flags from '../data/raw/flags';
import { isLoggedIn } from '../services/auth';
import Ticket from '../components/defaults/assets/svg/ticket.svg';
import { Archive } from '../components/defaults/assets/svg/mobilenav';

import { useRegistration } from '../services/typeformRegistration';

const GeneralFooter = ({...params}) => {
  const [loggedIn, setLoggedIn] = useState(false)
  const {registerButton, toggle} = useRegistration()

  const expo10 = {
    title: "Save Your Spot for EXPO 10",
    description: "EXPO 10 is right around the corner! Not only is it our first semi in-person (in-person is limited and invite only) event in over a year, but also our 5-year anniversary! Register now for your chance to participate at EXPO 10 later this month as we bring together innovators from around the world, showcasing new technologies, and celebrate our 10th EXPO.",
    icon: <Ticket />,
    iconFill: true,
    destination: {
      title: registerButton.buttonText,
      //url: "https://pnpgermany.typeform.com/to/RB0nRVxD",
      //icon: <Ticket />,
      trigger: toggle,
    },
    sub: {
      title: "Expo Rearview",
      url: "/archive",
      icon: <Archive />,
    }
  }
  
  const expo11 = {
    title: "Express interest for EXPO 11",
    description: "EXPO 10 might not be completely wrapped up yet, but we are already in preparation stage for our first big event in 2022. Apply now for your chance to participate the coming spring as we bring together innovators from around the world, showcasing new technologies and party another round of successfull STARTUP AUTOBAHN startups.",
    icon: <Ticket />,
    iconFill: true,
    destination: {
      title: "Apply now",
      url: "https://pnpgermany.typeform.com/to/RB0nRVxD",
      // icon: <Ticket />,
    },
    sub: {
      title: "Expo Rearview",
      url: "/archive",
      icon: <Archive />,
    }
  }

  useEffect(() => {
    setLoggedIn( isLoggedIn() )
  }, []);

  if ( true ) {
    return (
      <FooterBox content={expo11} {...params} />
    )
  }
}

export default GeneralFooter;