import React from "react"
import AdacLogo from './adac_pri_blk_rgb.svg'
import AgcLogo from './agc_pri_blk_rgb.svg'
import Arena2036Logo from './arena2036_pri_blk_rgb.svg'
import BasfLogo from './basf_pri_blk_rgb.svg'
import BoschLogo from './bosch_pri_blk_rgb.svg'
import BpLogo from './bp_pri_rgb_blk.svg'
import CeatLogo from './ceat_pri_blk_rgb.svg'
import DpdhlLogo from './dpdhl_pri_blk_rgb.svg'
import DxcLogo from './dxc_pri_blk_rgb.svg'
import EberspaecherLogo from './eberspaecher_pri_blk_rgb.svg'
import FaureciaLogo from './faurecia_pri_blk_rgb.svg'
import GrupoantolinLogo from './grupoantolin_pri_blk_rgb.svg'
import HufLogo from './huf_pri_blk_rgb.svg'
import HyundaiLogo from './hyundai_pri_blk_rgb.svg'
import IttLogo from './itt_pri_blk_rgb.svg'
import JardinesLogo from './jardines_pri_blk_rgb.svg'
import LindeLogo from './linde_pri_blk_rgb.svg'
import MaxionLogo from './maxionwheels_pri_blk_rgb.svg'
import MercedesbenzLogo from './mercedesbenz_pri_blk_rgb.svg'
import MothersonLogo from './motherson_pri_blk_rgb.svg'
import MurataLogo from './murata_pri_blk_rgb.svg'
import NovelisLogo from './novelis_pri_blk_rgb.svg'
import PlasticomniumLogo from './plasticomnium_pri_blk_rgb.svg'
import PnpLogo from './pnp_pri_blk_rgb.svg'
import PorscheLogo from './porsche_pri_blk_rgb.svg'
import SchaefflerLogo from './schaeffler_pri_blk_rgb.svg'
import SekisuiLogo from './sekisui_pri_blk_rgb.svg'
import StLogo from './st_pri_blk_rgb.svg'
import TuevLogo from './tuev_pri_blk_rgb.svg'
import UnistuttgartLogo from './uni_pri_blk_rgb.svg'
import WebastoLogo from './webasto_pri_blk_rgb.svg'
import WielandLogo from './wieland_pri_blk_rgb.svg'
import ZenzicLogo from  './zenzic_pri_blk_rgb.svg'
import ZFLogo from  './zf_pri_blk_rgb.svg'

export const Adac = () => { return( <AdacLogo /> ) }; 
export const Agc = () => { return( <AgcLogo /> ) }; 
export const Arena2036 = () => { return( <Arena2036Logo /> ) }; 
export const Basf = () => { return( <BasfLogo /> ) }; 
export const Bosch = () => { return( <BoschLogo /> ) }; 
export const Bp = () => { return( <BpLogo /> ) };
export const Ceat = () => { return( <CeatLogo />)};
export const Dpdhl = () => { return( <DpdhlLogo /> ) }; 
export const Dxc = () => { return( <DxcLogo /> ) }; 
export const Eberspaecher = () => { return( <EberspaecherLogo /> ) }; 
export const Faurecia = () => { return( <FaureciaLogo /> ) }; 
export const Grupoantolin = () => { return( <GrupoantolinLogo /> )};
export const Huf = () => { return( <HufLogo /> )};
export const Hyundai = () => { return( <HyundaiLogo /> ) }; 
export const Itt = () => { return( <IttLogo />) };
export const Jardines = () => { return( <JardinesLogo /> ) }; 
export const Linde = () => { return( <LindeLogo /> ) }; 
export const Maxion = () => { return( <MaxionLogo /> ) }; 
export const Mercedesbenz = () => { return( <MercedesbenzLogo /> ) }; 
export const Motherson = () => { return( <MothersonLogo /> ) }; 
export const Murata = () => { return( <MurataLogo /> ) }; 
export const Novelis = () => { return( <NovelisLogo /> ) }; 
export const Plasticomnium = () => { return( <PlasticomniumLogo /> ) }; 
export const Pnp = () => { return( <PnpLogo /> ) }; 
export const Porsche = () => { return( <PorscheLogo /> ) }; 
export const Schaeffler = () => { return( <SchaefflerLogo />)}
export const Sekisui = () => { return( <SekisuiLogo /> ) }; 
export const St = () => { return( <StLogo />) }
export const Tuev = () => { return( <TuevLogo /> ) }; 
export const Unistuttgart = () => { return( <UnistuttgartLogo /> ) }; 
export const Webasto = () => { return( <WebastoLogo /> ) }; 
export const Wieland = () => { return( <WielandLogo /> ) }; 
export const Zenzic = () => { return( <ZenzicLogo /> ) }; 
export const Zf = () => { return( <ZFLogo /> ) }; 
