import React from "react";

import {
  Adac,
  Agc,
  Arena2036,
  Basf,
  Bosch,
  Bp,
  Ceat,
  Dpdhl,
  Dxc,
  Eberspaecher,
  Faurecia,
  Grupoantolin,
  Huf,
  Hyundai,
  Itt,
  Jardines,
  Linde,
  Maxion,
  Mercedesbenz,
  Motherson,
  Murata,
  Novelis,
  Plasticomnium,
  Pnp,
  Porsche,
  Schaeffler,
  Sekisui,
  St,
  Tuev,
  Unistuttgart,
  Webasto,
  Wieland,
  Zenzic,
  Zf,
} from "../defaults/assets/logos/partnerlogos/index"

 const PartnerIcons = {
   recIe1apc42yOhArx: Adac,
   recoexoda2y1iwHrf: Agc,
   reccsJJCLA8orTqPv: Arena2036,
   recjWLp8bmOsW8IrF: Basf,
   recH4LUv5NqwSyg9e: Bosch,
   recTwqPsI58eMF5kd: Bp,
   recFhfdmwD2DU65hR: Ceat,
   recssR8QD02J4znnl: Dpdhl,
   rec3PcxrUl9Df4Prv: Dxc,
   rechKwsxkK4P2RoLy: Eberspaecher,
   recoq1f2XdsxAmoxD: Faurecia,
   recnsX8VzUWrsERci: Grupoantolin,
   recYOBh4O5MQEWf7Z: Huf,
   recZIR3w0kMc0G0Qh: Hyundai,
   recAfgxfTt2KQXqrf: Itt,
   recZ58XuiK5X9rjdd: Jardines,
   recoZ2oqTYtuxCvsV: Linde,
   receC9OGU8NrCzfzy: Maxion,
   receB8bHG36FFbjsd: Mercedesbenz,
   rec4GMieCxEeEZzGm: Motherson,
   recxwoXV3zRgFZiSj: Murata,
   recNi0YcbiZfF9Ay9: Novelis,
   recosWqw0VzjiGYK1: Plasticomnium,
   recfDhmMqe90sajDw: Pnp,
   recTKULGUnrW1A4Vw: Porsche,
   rechKkWhpVP2eoNcH: Schaeffler,
   recFsiUAevex1cnBX: Sekisui,
   recB7p0VhhHRP4UTq : St,
   recjgc5vG5WQdXg3E: Tuev,
   recmKOvVqFZnbrvRu: Unistuttgart,
   recXITpQsC8vSZnLs: Webasto,
   recctTEe0THWpqVh5: Wieland,
   recLQRJW3AttYMjHt: Zenzic,
   recmAxKi76FQXQec5: Zf,
 }

 const PartnerLogo = ({id, className}) => {
     let Icon = undefined;
     
     if (PartnerIcons[id] !== undefined) {
         Icon = PartnerIcons[id]
     } else {
         Icon = Pnp
     }

     return(
        <>
            { Icon ? (
                <Icon className={className} />
            ) : ''}
        </>
     )
 }

 export default PartnerLogo;