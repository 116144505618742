import React from 'react'

import AtlatecLogo from './atlatec_pri_blk_rgb.svg'
import BeeplanetLogo from './beeplanet_pri_blk_rgb.svg'
import BrighteraiLogo from './brighterai_pri_blk_rgb.svg'
import CybellumLogo from './cybellum_pri_blk_rgb.svg'
import DeliciousdataLogo from './deliciousdata_pri_blk_rgb.svg'
import DesignledLogo from './designled_pri_blk_rgb.svg'
import DrishtiLogo from './drishti_pri_blk_rgb.svg'
import DycotecLogo from './dycotec_pri_blk_rgb.svg'
import EmboldLogo from './embold_pri_blk_rgb.svg'
import EndelLogo from './endel_pri_blk_rgb.svg'
import ExcelforeLogo from './excelfore_pri_blk_rgb.svg'
import GermanautolabsLogo from './german_autolabs_pri_blk_rgb.svg'
import GestoosLogo from './gestoos_pri_blk_rgb.svg'
import KontrolLogo from './kontrol_pri_blk_rgb.svg'
import NodarLogo from './nodar_pri_blk_rgb.svg'
import NxrtLogo from './nxrt_pri_blk_rgb.svg'
import PeakboardLogo from './peakboard_pri_blk_rgb.svg'
import PlancoLogo from './planco_pri_blk_rgb.svg'
import PolymertalLogo from './polymertal_pri_blk_rgb.svg'
import PreactLogo from './preact_pri_blk_rgb.svg'
import S1SevenLogo from './s1seven_pri_blk_rgb.svg'
import SenselLogo from './sensel_pri_blk_rgb.svg'
import SpreadaiLogo from './spread_pri_blk_rgb.svg'
import TerranetLogo from './terranet_pri_wht_rgb.svg'
import TextiaLogo from './textia_pri_blk_rgb.svg'
import VencortexLogo from './vencortex_pri_blk_rgb.svg'
import ViezoLogo from './viezo_pri_blk_rgb.svg'
import WheelmeLogo from './wheelme_pri_blk_rgb.svg'
import YuriLogo from './yuri_pri_blk_rgb.svg'

export const Atlatec = () => { return (<AtlatecLogo />) }; 
export const Beeplanet = () => { return (<BeeplanetLogo /> )};
export const Brighterai = () => { return (<BrighteraiLogo />) }; 
export const Cybellum = () => { return (<CybellumLogo />)}
export const Deliciousdata = () => { return (<DeliciousdataLogo />) }; 
export const Designled = () => { return (<DesignledLogo />) }; 
export const Drishti = () => { return(<DrishtiLogo />)};
export const Dycotec = () => { return (<DycotecLogo />) };
export const Embold = () => { return (<EmboldLogo />) }; 
export const Endel = () => { return (<EndelLogo />) }; 
export const Excelfore = () => { return (<ExcelforeLogo />) }; 
export const Germanautolabs = () => { return (<GermanautolabsLogo />) };
export const Gestoos = () => { return (<GestoosLogo />) };  
export const Kontrol = () => { return (<KontrolLogo />) };  
export const Nodar = () => { return (<NodarLogo />) };  
export const Nxrt = () => { return (<NxrtLogo />) };  
export const Peakboard = () => { return (<PeakboardLogo />) };  
export const Planco = () => { return (<PlancoLogo />)}
export const Polymertal = () => { return (<PolymertalLogo />) };  
export const Preact = () => { return (<PreactLogo />) };  
export const S1Seven = () => { return (<S1SevenLogo />) };
export const Sensel = () => { return (<SenselLogo />) };
export const Spreadai = () => { return (<SpreadaiLogo />) };  
export const Terranet = () => { return (<TerranetLogo /> )};
export const Textia = () => { return (<TextiaLogo />) };  
export const Vencortex = () => { return (<VencortexLogo />) };  
export const Viezo = () => { return (<ViezoLogo />) };  
export const Wheelme = () => { return (<WheelmeLogo />) };
export const Yuri = () => { return (<YuriLogo />) };