import React, {useState} from 'react';
import { css } from '@emotion/react';
import { color } from '../styles/theme';
import mq from '../styles/mediaquerys';
import navigation from '../../../data/navigation';
import Link from '../../link';
import ChevronUp from '../assets/svg/chevron-up.svg';
import CloseIcon from '../assets/svg/closeIcon.svg'
import PlueDrawer from './plue_drawer';
import Flags from '../../../data/raw/flags';



const NavLink = ({el, children}) => {
  
  return (
    <Link 
      to={el.url}
      css={css`
        text-decoration: none;
        &:visited {
          color: unset;
        }
      `}
    >
      {children}
    </Link>
  )
}

const NavItem = ({className, el}) => {
  
  return (
    <div
      className={className}
      css={css`
        width: 3.5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
      `}
    >
      <div
        css={css`
          height: 1.25em;
          svg {
            height: 100%;
            width: auto;
            fill: ${color.gray700};
          }
        `}
      >
        {el.icon}
      </div>
      <p
        css={css`
          margin-top: 0.35em;
          color: ${color.gray700};
          font-size: 0.6em;
          font-weight: 500;
          letter-spacing: 0.03em;
          margin-bottom: 0;
        `}
      >
        {el.title}
      </p>
    </div>
  )
}

const NavButton = ({className, toggle, drawerActive}) => {
  
  const LiveTag = () => {
    return (
      <div css={css`
        background: ${color.plue_rec};
        border-radius: 2px;
        position: absolute;
        right: 40%;
        top: 65%;
      `}>
        <p css={css`
          margin: 0;
          padding: 0;
          line-height: 1;
          white-space: nowrap;
          text-transform: uppercase;
          font-weight: 700;
          letter-spacing: .075em;
          padding: 0.3em 0.5em;
          font-size: .6em;
          color: white;
        `}>Live</p>
      </div>
    )
  }
  
  return (
    <button
      className={className}
      onClick={toggle}
      css={css`
        padding: 0;
        border: none;
        width: 3.5rem;
        height: 4.25em;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: none;
        cursor: pointer;
      `}
    >
      <div css={css`
        height: 1.25em;
        position: relative;
        svg {
          height: 100%;
          width: auto;
        }
      `}>
        {drawerActive ? <CloseIcon /> : <ChevronUp />}
        {Flags.features.live ? <LiveTag /> : null}
      </div>
    </button>
  )
}

const PlueNavigationMobile = () => {
  const [drawerActive, setDrawerActive] = useState(false)
  
  const toggleDrawer = () => {
    setDrawerActive(prevState => !prevState);
  }

  return (
    <>
      <aside
        css={css`
          background: ${color.main_light};
          z-index: 99;
          position: fixed;
          bottom: 0;
          left: 0;
          width: 100%;
          ${mq[2]} {
            display: none;
          }
          &:after {
            content: "";
            z-index: -1;
            position: absolute;
            top: 0;
            height: 100%;
            width: 100%;
            box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px,
              rgb(0 0 0 / 14%) 0px 1px 5px 1px, rgb(0 0 0 / 12%) 0px 1px 5px 1px;
          }
        `}
      >
        <nav
          css={css`
            display: flex;
            justify-content: center;
          `}
        >
          <ul
            css={css`
              list-style: none;
              margin: 0;
              display: flex;
              justify-content: space-between;
              max-width: 35rem;
              width: 100%;
              height: 4.25em;
              padding-left: 1em;
              li {
                margin-bottom: 0;
                height: 100%;
              }
            `}
          >
            {navigation.mobile.navigation.map((el, index) => (
              <li key={index} css={css``}>
                {el.url ? (
                  <NavLink el={el}>
                    <NavItem el={el} />
                  </NavLink>
                ) : (
                  <NavItem
                    el={el}
                    css={css`
                      opacity: 0.35;
                    `}
                  />
                )}
              </li>
            ))}
            <li css={css``}>
              <NavButton toggle={toggleDrawer} drawerActive={drawerActive} />
            </li>
          </ul>
        </nav>
      </aside>
      <PlueDrawer drawerActive={drawerActive} toggleDrawer={toggleDrawer} />
    </>
  )
}

export default PlueNavigationMobile;
