import React, { useState, useRef, useEffect } from 'react';
import { css } from '@emotion/react';
import { color } from '../components/defaults/styles/theme';
import { IconButtonBtn, ArrowLinkBtn, TitleHeader } from '../components/defaults/styles/elements';
import Thumbsup from '../components/defaults/assets/svg/thumbsup.svg';
import config from '../../gatsby-config';
import Link from '../components/link';
import Infoicon from '../components/defaults/assets/svg/info.svg';
import mq from '../components/defaults/styles/mediaquerys';



const Analytics = () => {
  const [ analyticsNotifier, setAnalyticsNotifier ] = useState("");
  const [analyticsHeight, setAnalyticsHeight] = useState("0px");
  const notificationEl = useRef(null)

  const disableStr = 'ga-disable-' + config.plugins[0].options.trackingId;

  useEffect(() => {
    if ( !( document.cookie.indexOf('gaIgnore=true') > -1 ) && !(document.cookie.indexOf(disableStr + '=true') > -1) ) {
      toggleNotifier()
    }
  }, [disableStr]);

  const toggleNotifier = () => {
    setAnalyticsHeight(
      analyticsNotifier === "active" ? "0px" : `${notificationEl.current.scrollHeight}px`
    )
    setAnalyticsNotifier(analyticsNotifier === "" ? "active" : "")
  }
    
  const ignoreAnalytics = () => {
    document.cookie = 'gaIgnore=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/';
    toggleNotifier()
  }

  const gaOptOut = () => {
    // set cookie for a week
    document.cookie = disableStr + '=true; max-age=604800; path=/';
    window[disableStr]=!0;
    toggleNotifier()
  }


    
  return (
    <div>
      <section css={[css`
        background: ${color.main_dark};
        max-width: 45rem;
        width: 100%;
        position: fixed;
        bottom: 64px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        z-index: 99999;
        height: ${analyticsHeight};
        transition: height .6s ease-in-out;
        overflow: hidden;
        left: 50%;
        transform: translateX(-50%);
        padding: 0;
        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 1px;
          background: white;
          opacity: .5;
        }
        ${mq[2]} {
          bottom: 0;
        }
      `]} ref={notificationEl}>
        <div css={css`
          padding: 1.5em 1em 2.5em;
          ${mq[0]} {
            padding: 2em;
          }
        `}>
          <div>
            <TitleHeader title="Cookie Information" icon={<Infoicon />} size="1" dark inline transparent css={css`
              padding: 0;
            `} />
            <p css={css`
              color: white;
              font-size: .65em;
              line-height: 1.6em;
              margin-bottom: 0;
              a {
                color: white;
                position: relative;
                text-decoration: none;
                border-bottom: 1px solid white;
              }
            `}>
              This website uses Analytics, a web analysis service of Google Inc. You may opt-out of anonymous tracking at any time by clicking the respective button below. A cookie will be set, which avoids having your information collected by this website with future effect. If you would like to have more information you can also take a look at our <Link to="/privacy-policy">privacy policy</Link>.
            </p>
          </div>
          <div css={css`
            margin-top: 1em;
            display: flex;
            align-items: center;
          `}>
            <IconButtonBtn css={css`
              &:before {
                background: ${color.plue300};
              }
            `} name="Got it" icon={<Thumbsup />} onClick={ignoreAnalytics} />
            <ArrowLinkBtn text="I‘d rather not opt-in" onClick={gaOptOut} css={css`
              font-weight: 500;
              font-size: .65em;
              text-transform: none;
              opacity: .5;
              margin-top: 0;
              margin-bottom: 0;
              margin-left: 1.5em;
            `}/>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Analytics;