import React from "react";
import ExpoLogoSec from '../defaults/assets/logos/expo_sec_blk_rgb.svg'
import { 
  Atlatec, 
  Beeplanet,
  Brighterai,
  Cybellum,
  Deliciousdata, 
  Designled, 
  Drishti,
  Dycotec,
  Embold, 
  Endel, 
  Excelfore, 
  Germanautolabs, 
  Gestoos, 
  Kontrol, 
  Nodar, 
  Nxrt, 
  Peakboard, 
  Polymertal, 
  Planco,
  Preact, 
  S1Seven,
  Sensel,
  Spreadai, 
  Terranet, 
  Textia, 
  Vencortex, 
  Viezo, 
  Wheelme,
  Yuri
  } from '../defaults/assets/logos/startuplogos/index';

  const StartupIcons = {
   recRxkFgU1ZESZcAN: Atlatec, 
   recXhASP63Sa8cpDv: Beeplanet,
   recEq4RMkn0cZowXu: Brighterai,
   recirjVXkAWmujua4: Cybellum,
   recgjjD2b3Q5TLVNF: Deliciousdata, 
   recpeqbCpD2e6pOho: Designled, 
   recodQ4GWmuMO2v2P: Drishti,
   recEroLAuWH66zbDT: Dycotec,
   recvUWof9ra2mRI2P: Embold, 
   recqVm6dlRbzx3oTW: Endel, 
   receI5JV6OW6wdM9q: Excelfore, 
   recFr9w5eorJkEyKc: Germanautolabs, 
   reck0KNrfDgF6tH5Z: Gestoos, 
   rechoCUrdfSQusnLx: Kontrol, 
   recDWM4MydpQt8Ij3: Nodar, 
   rec1qhRNwvnM9Psqn: Nxrt, 
   recKaSk9VNiJqL7g6: Peakboard, 
   recDqU2UjTIL5YS8e: Polymertal,
   recR0iOkbPqQfe8ke: Planco,
   reccpJuum5P7XAAdz: Preact, 
   recPmAs1VnW2pcedS: S1Seven,
   reco6BNDc8A0so9TN: Sensel,
   rec9v175xcflZIxh1: Spreadai, 
   recZZ7OLZTV1KniJw: Terranet, 
   recBKFSXWZZa7nPXm: Textia, 
   reciyeFAK7uRhViq1: Vencortex, 
   recRlGYtHiCUiyn1l: Viezo, 
   recUj2gCiTdCbwyhK: Wheelme,
   recAq6ZDtfpLRUIom: Yuri
  }

  const StartupLogo = ({ id }) => {
    let Icon = undefined

    if (StartupIcons[id] !== undefined) {
      Icon = StartupIcons[id]
    } else {
      Icon = ExpoLogoSec
    }
    return <>{Icon ? <Icon /> : ""}</>
  }

  export default StartupLogo;