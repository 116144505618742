export const isBrowser = () => typeof window !== "undefined"

const credentials = [
  {
    passphrase: process.env.GATSBY_PASSPHRASE,
    ticket_type: "inside_member",
    name: "Member Access",
    secret: process.env.GATSBY_SECRET,
  },
  {
    passphrase: "guestsstartupautobahnexpo",
    ticket_type: "inside_guest",
    name: "Member Access (Guest)",
    secret: process.env.GATSBY_SECRET,
  },
]

export const getUser = () =>
  isBrowser() && window.localStorage.getItem("EXPOuser")
    ? JSON.parse(window.localStorage.getItem("EXPOuser"))
    : {} 

export const setUser = user =>
  window.localStorage.setItem("EXPOuser", JSON.stringify(user))


export const handleLogin = ({ accesscode }) => {
  for(var i = 0; i < credentials.length; i += 1) {
    if(credentials[i]["passphrase"] === accesscode) {
        return setUser(credentials[i])
    }
  }
  return false
};


export const isLoggedIn = () => {
  let userSecret = getUser().secret;

  for (let key in credentials) {
    let obj = credentials[key]
    if (obj.secret === userSecret) {
      return !!obj.secret
    }
  }

};

export const logout = callback => {
  setUser({})
  callback()
};