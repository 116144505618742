import React from 'react';
import { navigate } from 'gatsby';
import { css } from '@emotion/react';
import { isLoggedIn, logout } from '../../../services/auth';
import { IconButton } from '../styles/elements';
import Flags from '../../../data/raw/flags';
import Lock from '../assets/svg/lock.svg';
import LockOpen from '../assets/svg/lock_open.svg';

const PlueLoginButton = ({ className }) => {
  if ( Flags.features.allAccess ) {
    return (
      isLoggedIn() ? (
        <IconButton
          url="/"
          onClick={event => {
            event.preventDefault()
            logout(() => navigate(`/${Flags.settings.protectedArea.url}/login`))
          }}
          name="Log Out"
          icon={<LockOpen />}
          className={className}
          css={css`
            display: flex;
            align-items: center;
          `}
        />
      ) : (
        <IconButton
          url={`/${Flags.settings.protectedArea.url}/login`}
          name={Flags.settings.protectedArea.title}
          icon={<Lock />}
          className={className}
          css={css`
            display: flex;
            align-items: center;
          `}
        />
      )
    )
  } else return null;
}

export default PlueLoginButton;